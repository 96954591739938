<template>
  <div class="w-full h-full">
    <UtilLoader v-if="!isErrorLoading" :isLoading="!isLoaded">
      <template #skeleton v-if="$slots.skeleton">
        <slot name="skeleton" />
      </template>

      <template #default>
        <slot />
      </template>
    </UtilLoader>

    <slot v-else name="error">
      <UtilMessage type="error">Error loading {{ this.scriptId }}. Please reload and try again</UtilMessage>
    </slot>
  </div>
</template>

<script>
import UtilLoader from '@/components/utils/UtilLoader'
import UtilMessage from '@/components/utils/UtilMessage'

// This allows us to only load a JS script when it's required
export default {
  name: 'UtilScriptLoader',
  components: {
    UtilLoader,
    UtilMessage
  },
  props: {
    scriptId: {
      type: String,
      required: true
    },
    scriptUrl: {
      type: String,
      required: true
    },
    cssUrl: String
  },
  data() {
    return {
      isLoaded: false,
      isErrorLoading: false
    }
  },
  created() {
    if (this.scriptUrl) {
      this.loadScript()
    }
  },
  methods: {
    loadScript() {
      const scriptElement = document.getElementById(this.scriptId)

      // If we have already injected the script
      if (scriptElement) {
        this.isLoaded = true
        this.$emit('ready')
        return
      }

      if (this.cssUrl) {
        this.loadCss()
      }

      const script = document.createElement('script')
      script.src = this.scriptUrl
      script.id = this.scriptId
      script.type = 'text/javascript'

      script.addEventListener(
        'load',
        () => {
          this.isLoaded = true
          this.$emit('ready')
        },
        {
          once: true
        }
      )

      script.addEventListener(
        'error',
        event => {
          this.$log.error(`Error loading ${this.scriptId} script`, event)

          this.isErrorLoading = true

          this.$emit('error')
        },
        { once: true }
      )

      document.head.appendChild(script)
    },

    loadCss() {
      let link = document.createElement('link')
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('href', this.cssUrl)
      link.setAttribute('type', 'text/css')
      document.head.appendChild(link)
    }
  }
}
</script>
