<template>
  <div class="flex" @click="$emit('click', match.userRef)">
    <div class="flex-auto">
      <span class="font-medium block">{{ match.name }}</span>
      <span class="font-medium text-sm block">{{ match.region }}</span>
      <span v-if="showStats" class="text-sm block">
        <span class="mr-3" v-if="match.listed">
          <FontAwesomeIcon class="text-gray-500" :icon="faCow" />
          {{ match.listed }}
        </span>
        <span class="mr-3" v-if="match.bought">
          <FontAwesomeIcon class="text-gray-500" :icon="faHandshake" />
          {{ match.bought }}
        </span>
        <span class="mr-3" v-if="match.enquired">
          <FontAwesomeIcon class="text-gray-500" :icon="faPhone" />
          {{ match.enquired }}
        </span>
        <span class="mr-3" v-if="match.viewed">
          <FontAwesomeIcon class="text-gray-500" :icon="faEyeSlash" />
          {{ match.viewed }}
        </span>
      </span>
    </div>
    <div v-if="match.score" class="rounded border p-4 bg-gray-200 text-2xl">
      {{ Math.round(match.score) }}
    </div>
  </div>
</template>

<script>
import { faHandshake, faPhone, faEyeSlash, faCow } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'UtilMatch',
  props: {
    match: {},
    showStats: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      faHandshake,
      faPhone,
      faEyeSlash,
      faCow
    }
  }
}
</script>

<style lang="scss" scoped></style>
