<template>
  <FormInputValidation :name="name" :rules="rules" v-slot="{ errors }">
    <label
      :data-tooltip="tooltip"
      class="form-checkbox cursor-pointer select-none flex items-center"
      :class="[{ disabled, tooltip, center }]"
    >
      <input type="checkbox" v-model="compValue" :value="trueValue" :name="name" :disabled="disabled" />

      <FontAwesomeIcon :icon="faSquare" class="icon icon-unselected" />
      <FontAwesomeIcon :icon="faCheckSquare" class="icon icon-selected" />

      <slot name="label">
        <span class="label">
          {{ label }}
        </span>
      </slot>
    </label>

    <FormInputHint v-if="showValidationError" :error="errors[0]" :hint="hint">
      <template slot="hint">
        <slot name="hint"></slot>
      </template>
    </FormInputHint>
  </FormInputValidation>
</template>

<script>
import FormInputValidation from '@/components/forms/elements/FormInputValidation'
import FormInputHint from '@/components/forms/elements/FormInputHint'
import { faSquare } from '@fortawesome/pro-light-svg-icons'
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'FormCheckbox',
  components: {
    FormInputValidation,
    FormInputHint
  },
  props: {
    label: String,
    rules: [String, Object],
    hint: String,
    tooltip: String,
    center: Boolean,
    value: [Boolean, String, Number, Array],
    trueValue: {
      type: null,
      default: 1
    },
    falseValue: {
      type: null,
      default: 0
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showValidationError: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      faSquare,
      faCheckSquare
    }
  },
  computed: {
    compValue: {
      set(newVal) {
        if (newVal) {
          if (Array.isArray(this.value)) {
            this.$emit('input', [...this.value, this.trueValue])
          } else {
            this.$emit('input', this.trueValue)
          }
        } else {
          if (Array.isArray(this.value)) {
            this.$emit(
              'input',
              this.value.filter(v => v !== this.trueValue)
            )
          } else {
            this.$emit('input', this.falseValue)
          }
        }

        this.$emit('change')
      },
      get() {
        if (Array.isArray(this.value) && this.value.includes(this.trueValue)) {
          return true
        } else if (this.value === this.trueValue) {
          return true
        }

        return false
      }
    }
  }
}
</script>
