<template>
  <div>
    <div class="flex bg-white shadow rounded py-4 px-1">
      <div class="flex-shrink-0 w-1/4 px-2">
        <form @submit.prevent="getListings()" class="bg-gray-200 p-4 rounded border sticky top-0">
          <FormLabel>Breed(s)</FormLabel>
          <MultiSelect
            v-model="listingsApi.model.categories"
            :taggable="true"
            :multiple="true"
            :options="categoriesApi.data.map(i => i.Ref)"
            :custom-label="opt => categoriesApi.data.find(i => i.Ref === opt).Name"
            placeholder="e.g. Texel"
            class="mb-2"
          />

          <FormLabel>Sexes</FormLabel>
          <MultiSelect
            v-model="listingsApi.model.sexes"
            :taggable="true"
            :multiple="true"
            :options="options.sexes.map(i => i.value)"
            :custom-label="opt => options.sexes.find(i => i.value === opt).label"
            placeholder="e.g Rams"
            class="mb-2"
          />

          <div class="flex flex-wrap">
            <FormCheckbox
              label="Pregnant"
              name="pregnant"
              v-model="listingsApi.model.pregnant"
              class="mr-2"
              :trueValue="true"
              :falseValue="false"
            />
            <FormCheckbox
              label="TB Restricted"
              name="tbRestricted"
              v-model="listingsApi.model.tbRestricted"
              class="mr-2"
              :trueValue="true"
              :falseValue="false"
            />
            <FormCheckbox
              label="Pedigree"
              name="pedigree"
              v-model="listingsApi.model.pedigree"
              class="mr-2"
              :trueValue="true"
              :falseValue="false"
            />
            <FormCheckbox
              label="Organic"
              name="organic"
              v-model="listingsApi.model.organic"
              class="mr-2"
              :trueValue="true"
              :falseValue="false"
            />
          </div>

          <FormSelect
            name="distance"
            v-model="listingsApi.model.distance"
            label="Distance from"
            placeholder="Please select"
            :items="options.distance"
            targetClass="w-full"
          />

          <div class="border rounded relative overflow-hidden">
            <FormLatLngPicker v-model="listingsApi.model.coordinates" height="300" />
          </div>

          <UtilButton color="primary" type="submit" :block="true" :isLoading="listingsApi.isLoading" class="mt-2">
            Submit
          </UtilButton>
        </form>
      </div>
      <div class="w-3/4 flex">
        <div class="flex-shrink-0 border-l w-1/2 px-2" v-if="listingsApi.data.buyers">
          <h4 class="font-medium text-center leading-none text-lg mb-4">Buyers</h4>
          <UtilLoader :isLoading="listingsApi.isLoading">
            <UtilMatch
              v-for="match in listingsApi.data.buyers"
              :match="match"
              :key="`buyer-${match.userRef}`"
              class="bg-gray-100 border-2 rounded p-3 mb-3 cursor-pointer hover:border-gray-500"
              @click="openMatch"
            />
          </UtilLoader>
        </div>
        <div class="flex-shrink-0 border-l w-1/2 px-2" v-if="listingsApi.data.sellers">
          <h4 class="font-medium text-center leading-none text-lg mb-4">Sellers</h4>

          <UtilLoader :isLoading="listingsApi.isLoading">
            <UtilMatch
              v-for="match in listingsApi.data.sellers"
              :match="match"
              :key="`seller-${match.userRef}`"
              class="bg-gray-100 border-2 rounded p-3 mb-3 cursor-pointer hover:border-gray-500"
              @click="openMatch"
            />
          </UtilLoader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/lib/api'
import UtilLoader from '@/components/utils/UtilLoader'
import UtilMatch from '@/components/utils/UtilMatch'
import UtilButton from '@/components/utils/UtilButton'
import FormLatLngPicker from '@/components/forms/elements/FormLatLngPicker.vue'
import FormCheckbox from '@/components/forms/elements/FormCheckbox.vue'
import FormSelect from '@/components/forms/elements/FormSelect.vue'
import FormLabel from '@/components/forms/elements/FormLabel.vue'

export default {
  name: 'InsightsListings',
  components: {
    UtilLoader,
    UtilMatch,
    UtilButton,
    FormLatLngPicker,
    FormCheckbox,
    FormSelect,
    FormLabel
  },
  data() {
    return {
      listingsApi: new Api({
        categories: [],
        sexes: [],
        organic: false,
        pedigree: false,
        tbRestricted: false,
        pregnant: false,
        coordinates: [],
        distance: 1000000
      }),
      categoriesApi: new Api(),
      options: {
        distance: [
          { label: '25 miles', value: 40000 },
          { label: '50 miles', value: 80000 },
          { label: '75 miles', value: 120000 },
          { label: '100 miles', value: 160000 },
          { label: '200 miles', value: 320000 },
          { label: 'UK Wide', value: 1000000 }
        ],
        sexes: [
          { label: 'Heifers', value: 'Heifer' },
          { label: 'Steers', value: 'Steer' },
          { label: 'Bulls', value: 'Bull' },
          { label: 'Cows', value: 'Cow' },
          { label: 'Calves', value: 'Calves' },
          { label: 'Rams', value: 'Ram' },
          { label: 'Lambs', value: 'Lamb' },
          { label: 'Ewes', value: 'Ewe' },
          { label: 'Shearlings', value: 'Shearling' },
          { label: 'Gimmers', value: 'Gimmer' },
          { label: 'Wethers', value: 'Wether' },
          { label: 'Hoggs', value: 'Hogg' }
        ]
      }
    }
  },
  created() {
    this.setListings()
    this.getCategories()
  },
  computed: {},
  methods: {
    setListings() {
      let q = this.$route.query.q

      if (q) {
        this.listingsApi.model = JSON.parse(q)
        this.getListings()
      }
    },

    async getListings() {
      this.listingsApi.get('http://localhost:3000/local/analytics/v1/matchmaker')

      this.$router.push({ query: { q: JSON.stringify(this.listingsApi.model) } })
    },

    getCategories() {
      this.categoriesApi.get('http://localhost:3000/local/analytics/v1/categories')
    },

    openMatch(userRef) {
      this.$modal.open('ModalMatch', { userRef: userRef })
    }
  }
}
</script>
