<template>
  <UtilScriptLoader
    :style="{ height: `${height}px` }"
    @ready="setup()"
    scriptId="leaflet-js"
    scriptUrl="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
    cssUrl="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
  >
    <template #skeleton>TODO: Loading... show a static map image or spinner here...</template>

    <template #default>
      <div id="map" :style="{ height: `${height}px` }" class="w-full h-full z-1 relative"></div>
    </template>
  </UtilScriptLoader>
</template>

<script>
import { debounce } from 'lodash'
import UtilScriptLoader from '@/components/utils/UtilScriptLoader'

export default {
  name: 'ActivityMap',
  components: {
    UtilScriptLoader
  },
  props: {
    value: Array,
    height: {
      default: 300
    }
  },
  data() {
    return {
      map: null,
      mapMarker: null,
      currentZoomLevel: 6
    }
  },
  methods: {
    async setup() {
      this.$log.debug('Setting up map')

      await this.$nextTick()

      const centerUKLatLng = ['53.310078', '-1.386719']

      this.map = window.L.map('map', {
        center: centerUKLatLng,
        zoom: this.currentZoomLevel,
        minZoom: 6,
        maxZoom: 10,
        // scrollWheelZoom: false,
        maxBoundsViscosity: 0.2,
        attributionControl: false
      })

      window.L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
        noWrap: true,
        maxNativeZoom: 20,
        maxZoom: 20
      }).addTo(this.map)

      this.fitEntireUKInView()

      this.addEventHandlers()
    },

    addEventHandlers() {
      window.addEventListener('resize', this.fitEntireUKInView)

      this.map.on('zoomend', event => {
        this.currentZoomLevel = event.target._zoom

        console.log('Zoom changed', this.currentZoomLevel)
      })

      this.map.on('click', event => {
        console.log('Map clicked', event)

        if (this.mapMarker) {
          this.mapMarker.setLatLng([event.latlng.lat, event.latlng.lng])
        } else {
          this.mapMarker = window.L.marker([event.latlng.lat, event.latlng.lng]).addTo(this.map)
        }

        this.$emit('input', [event.latlng.lat, event.latlng.lng])
      })
    },

    fitEntireUKInView: debounce(function () {
      this.$log.debug('Zooming map to fit entire UK into view')

      const northWest = window.L.latLng(58.6541, -11.5137)
      const southEast = window.L.latLng(50.0642, 2.1973)

      let mapBounds = window.L.latLngBounds(northWest, southEast)

      this.map.fitBounds(mapBounds)

      let extendedMapBounds = mapBounds.pad(0.1)

      this.map.setMaxBounds(extendedMapBounds)
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
#map {
  cursor: crosshair;
}
</style>
